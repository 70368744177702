import axios from 'axios';

async function callGPT(query) {
 const apiUri = 'https://kr4bobhvt2.execute-api.eu-west-1.amazonaws.com/prod/run';
 const apiKey = 'KIuC7aHQC6aaLg7dRXEAC1Erkc5hF2el8gS3g6Es';

 const response = await axios.post(apiUri, query, {
  headers: {
   'x-api-key': apiKey,
   'Content-Type': 'application/json',
  },
 });
 console.log(response);
 return response;
}

export default callGPT;
