/* eslint-disable no-nested-ternary */

import { Stack } from '@mui/material';

import { animated, useTransition } from '@react-spring/web';
import React, { memo } from 'react';
import 'react-multi-carousel/lib/styles.css';
import ChatMessages from './ChatMessages';

const ConversationView = memo(({ conversations, isTyping = false }) => {
 const transitions = useTransition(
  // eslint-disable-next-line no-return-assign
  conversations,
  {
   key: (item) => item.id,
   from: {
    opacity: 0,
   },
   enter: () => ({ opacity: 1 }),
   leave: {
    opacity: 0,
    height: 0,
   },
  }
 );

 console.log(isTyping);

 return (
  <>
   <style>
    {`
        /* .chat-bubble {
          height: 72px;
          width: 46px;
          background: #e5e5e5;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .chat-bubble:before {
          content: "";
          height: 72px;
          width: 72px;
          left: -36px;
          position: absolute;
          display: block;
          background: #e5e5e5;
          border-radius: 50%;
          z-index: 1;
        }
        .chat-bubble:after {
          content: "";
          height: 72px;
          width: 72px;
          right: -36px;
          position: absolute;
          display: block;
          background: #e5e5e5;
          border-radius: 50%;
          z-index: 1;
        }
        .chat-bubble .tail {
          height: 25px;
          width: 25px;
          background: #e5e5e5;
          position: absolute;
          left: -40px;
          bottom: 2px;
          border-radius: 50%;
        }
        .chat-bubble .tail:before {
          height: 12px;
          width: 12px;
          background: #e5e5e5;
          content: "";
          display: block;
          border-radius: 50%;
          position: absolute;
          left: -10px;
          bottom: -8px;
        }
        .chat-bubble .loading {
          position: absolute;
          z-index: 10;
          width: 67px;
          left: -11px;
          top: 27px;
        } */
        
        .chat-bubble .loading {
          margin: 8px 0;
          height: 10px;
        }
        
        .chat-bubble .loading .dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: #c1c1c1;
          display: block;
          float: left;
          margin: 0 0 0 8px;
        }
        .chat-bubble .loading .dot:first-child {
          margin: 0;
        }
        .chat-bubble .loading .dot.one {
          -webkit-animation: cycleOne 1s ease-in-out infinite;
          -webkit-animation-direction: normal;
        }
        .chat-bubble .loading .dot.two {
          -webkit-animation: cycleTwo 1s ease-in-out infinite;
          -webkit-animation-direction: normal;
        }
        .chat-bubble .loading .dot.three {
          -webkit-animation: cycleThree 1s ease-in-out infinite;
          -webkit-animation-direction: normal;
        }
        
        @keyframes cycleOne {
          0% {
            background: rgba(150, 150, 150, 0.4);
          }
          33.333% {
            background: #969696;
          }
          66.6667% {
            background: rgba(150, 150, 150, 0.4);
          }
          100% {
            background: rgba(150, 150, 150, 0.4);
          }
        }
        @keyframes cycleTwo {
          0% {
            background: rgba(150, 150, 150, 0.4);
          }
          33.333% {
            background: rgba(150, 150, 150, 0.4);
          }
          66.6667% {
            background: #969696;
          }
          100% {
            background: rgba(150, 150, 150, 0.4);
          }
        }
        @keyframes cycleThree {
          0% {
            background: rgba(150, 150, 150, 0.4);
          }
          33.333% {
            background: rgba(150, 150, 150, 0.4);
          }
          66.6667% {
            background: rgba(150, 150, 150, 0.4);
          }
          100% {
            background: #969696;
          }
        }
        
        
        `}
   </style>
   <Stack direction="column" spacing={2}>
    {transitions((style, conversation, t, index) => (
     <animated.div key={index} style={{ ...style }}>
      <ChatMessages isTyping={isTyping} messages={conversation.messages} side={conversation.side} />
     </animated.div>
    ))}
   </Stack>
  </>
 );
});

export default ConversationView;
