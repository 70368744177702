import logo from './logo.svg';
import './App.css';
import AskDataHubDialog from './components/GptDialog2';

function App() {
 return (
  <div className="App">
   <header className="App-header"></header>
   <body>
    <AskDataHubDialog />
   </body>
  </div>
 );
}

export default App;
